<template>
    <div>
        <b-card v-if="loadingState" class="d-flex">
            <Loading />
        </b-card>

        <div v-if="showSettingsField">
            <b-card>
                <b-card-text class="text-center position-relative">
                    <h2 v-text="$t(locale.title)" />

                    <img
                        @click="guideModal = !guideModal"
                        width="35px"
                        class="position-absolute"
                        src="@/assets/duplioAsset/icons/helpCircle.png"
                        style="right: 33px; top: 20px; cursor: pointer"
                    />
                </b-card-text>

                <div class="mt-5">
                    <div class="container">
                        <ValidationObserver ref="form">
                            <b-row>
                                <!-- LeftSide -->
                                <b-col
                                    xl="6"
                                    class="d-flex p-2 flex-column"
                                    style="gap: 1.5rem"
                                >
                                    <!-- <div
                                        class="d-flex flex-column"
                                        style="gap: 1rem"
                                    >
                                        <b-card-title
                                            v-text="$t(locale.resource)"
                                        />

                                        <p
                                            class="mb-0"
                                            v-text="
                                                $t(locale.resourceSubtitle, {
                                                    number: selectedResource.length,
                                                })
                                            "
                                        />

                                        <b-row>
                                            <b-col xl="8" class="">
                                                <ValidationProvider
                                                    #default="{errors}"
                                                    rules="required"
                                                    :name="
                                                        $t(
                                                            locale.chooseResource
                                                        )
                                                    "
                                                >
                                                    <div
                                                        class="d-flex flex-column"
                                                    >
                                                        <v-select
                                                            @option:deselecting="
                                                                deselecting
                                                            "
                                                            @option:selected="
                                                                selected
                                                            "
                                                            v-model="
                                                                selectedResource
                                                            "
                                                            multiple
                                                            :clearable="false"
                                                            :options="
                                                                resourceList
                                                            "
                                                            :placeholder="
                                                                $t(
                                                                    locale.chooseResource
                                                                )
                                                            "
                                                            class="resource-selector d-inline-block"
                                                        />
                                                        <small
                                                            class="text-danger"
                                                            v-text="errors[0]"
                                                        />
                                                    </div>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </div> -->

                                    <div
                                        class="d-flex flex-column"
                                        style="gap: 1rem"
                                    >
                                        <b-card-title
                                            v-text="$t(locale.uniqueTitle)"
                                        />

                                        <p
                                            class="mb-0"
                                            v-text="$t(locale.uniqueSubtitle)"
                                        />

                                        <template v-if="settingsList">
                                            <b-row
                                                v-for="(
                                                    item, index
                                                ) in settingsList.transactionUnique"
                                                :key="index"
                                            >
                                                <b-col xl="7">
                                                    <!-- Pending check select options -->
                                                    <ValidationProvider
                                                        #default="{errors}"
                                                        :name="
                                                            $t(
                                                                locale.chooseField,
                                                                {
                                                                    text: renderResource(getLang,item.field) ,
                                                                }
                                                            )
                                                        "
                                                    >
                                                        <p class="pb-0 mb-0">
                                                            {{
                                                                $t(
                                                                    locale.chooseField,
                                                                    {
                                                                        text: renderResource(getLang,item.field) ,
                                                                    }
                                                                )
                                                            }}
                                                        </p>

                                                        <v-select
                                                            :clearable="false"
                                                            v-model="item.value"
                                                            :options="
                                                                renderTransactionDropdown(
                                                                    transactionLang,
                                                                    item.field
                                                                )
                                                            "
                                                            :placeholder="
                                                                $t(
                                                                    locale.chooseField,
                                                                    {
                                                                        text: item.field,
                                                                    }
                                                                )
                                                            "
                                                            class="per-page-selector-send-method mt-2 d-inline-block"
                                                        />

                                                        <small
                                                            class="text-danger"
                                                            v-text="errors[0]"
                                                        />
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                        </template>
                                    </div>
                                </b-col>

                                <b-col
                                    xl="6"
                                    class="d-flex p-2 flex-column"
                                    style="gap: 1.5rem"
                                >
                                    <div
                                        class="d-flex flex-column"
                                        style="gap: 1rem"
                                    >
                                        <b-card-title
                                            v-text="$t(locale.notifTitle)"
                                        />

                                        <b-form-checkbox
                                            v-model="
                                                settingsList.notify_about_new_duplicates
                                            "
                                            class="custom-control-primary"
                                        >
                                            {{ $t(locale.notifSubtitle) }}
                                        </b-form-checkbox>

                                        <b-row>
                                            <b-col xl="6">
                                                <ValidationProvider
                                                    #default="{errors}"
                                                    :rules="notificationEmail"
                                                    :name="$t(locale.notif)"
                                                >
                                                    <b-form-input
                                                        v-model="
                                                            settingsList.notification_email
                                                        "
                                                        :placeholder="
                                                            $t(locale.notif)
                                                        "
                                                    />
                                                    <small
                                                        class="text-danger"
                                                        v-text="errors[0]"
                                                    />
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                    </div>

                                    <!-- <div
                                        class="d-flex flex-column"
                                        style="gap: 1rem"
                                    >
                                        <b-card-title
                                            v-text="$t(locale.autoHandle)"
                                        />

                                        <b-form-checkbox
                                            class="custom-control-primary"
                                            style="width: 95%"
                                            v-model="
                                                settingsList.automatically_handle_duplicates
                                            "
                                        >
                                            {{ $t(locale.autoHandleSubtitle) }}
                                        </b-form-checkbox>
                                    </div> -->
                                </b-col>
                                <b-col
                                    xl="12"
                                    class="d-flex my-5 justify-content-center"
                                    sm="12"
                                >
                                    <div style="width: 300px">
                                        <b-button
                                            v-ripple.400
                                            variant="primary"
                                            @click="
                                                updateConfiguration(
                                                    settingsList
                                                )
                                            "
                                            block
                                        >
                                            <span v-if="loading">
                                                <b-spinner small type="grow" />
                                                {{ $t('Message.loading') }}...
                                            </span>

                                            <span
                                                v-else
                                                v-text="
                                                    $t('SettingPageCard.save')
                                                "
                                            />
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </ValidationObserver>
                    </div>
                </div>
            </b-card>
        </div>
        <b-modal
            @shown="generateImage"
            ref="modal"
            v-model="guideModal"
            ok-only
            hide-footer
            ok-variant="primary"
            :ok-title="$t('SubscriptionCard.ModalConfirm')"
            modal-class="modal-primary"
            centered
            clickToClose
            :title="$t(locale.guide)"
        >
            <b-card-text class="d-flex flex-column" style="gap: 1rem">
                <p class="font-weight-bold" v-text="$t(locale.guideSubtitle)" />
            </b-card-text>

            <b-card
                class="border border-secondaryColor"
                v-for="(dataText, index) in guideText"
                :key="index"
            >
                <span class="spanGuides" v-html="dataText[guide]" />
            </b-card>
        </b-modal>
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import vSelect from 'vue-select';
import useAppConfig from '@core/app-config/useAppConfig';
import Ripple from 'vue-ripple-directive';
import {settingsMixins} from '@/mixins/settingsMixins';

export default {
    mixins: [settingsMixins],
    directives: {
        Ripple,
    },
    components: {
        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            config: useAppConfig(),
            loading: false,
            loadingState: true,
            showSettingsField: false,
            configurationParams: {},
            btnDisabled: true,
            guideModal: false,
            guideText: '',
            checkForm: '',
            settingsList: '',
            copySettings: '',
            transactionUnique: '',
            checkField: '',
            selectOptions: [],
            locale: {
                title: 'SettingPageCard.title',
                resource: 'SettingPageCard.resource',
                resourceSubtitle: 'SettingPageCard.resourceSubtitle',
                chooseResource: 'SettingPageCard.chooseResource',
                uniqueTitle: 'SettingPageCard.uniqueTransactionTitle',
                uniqueSubtitle: 'SettingPageCard.uniqueTranactionSubtitle',
                notifTitle: 'SettingPageCard.notification',
                notifSubtitle: 'SettingPageCard.notificationSubtitle',
                notif: 'SettingPageCard.notificationField',
                chooseField: 'SettingPageCard.chooseField',
                autoHandle: 'SettingPageCard.autoHandle',
                autoHandleSubtitle: 'SettingPageCard.autoHandleSubtitle',
                guide: 'SettingPageCard.guide',
                guideSubtitle: 'SettingPageCard.guideSubtitle',
                guideText: 'SettingPageCard.guideText',
            },
        };
    },

    computed: {
        selectOptionsByLang() {
            return {
                eng_fields: this.selectOptions.filter(
                    element => element.language == 'eng_fields'
                ),
                swe_fields: this.selectOptions.filter(
                    element => element.language == 'swe_fields'
                ),
            };
        },
        notificationEmail() {
            return this.settingsList.notify_about_new_duplicates
                ? 'required|email'
                : '';
        },
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        guide() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_text' : 'eng_text';
            } else return this.getLang == 'sv' ? 'swe_text' : 'eng_text';
        },
        transactionLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_fields' : 'eng_fields';
            } else return this.getLang == 'sv' ? 'swe_fields' : 'eng_fields';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    watch: {
        getLang() {
            this.changeLanguage();
        },
    },

    methods: {
        renderResource(language, valueResource) {
            let resource = this.$t('Duplicates.resource').find(
                element => element.value == valueResource
            );

            return resource.label;
        },
        changeLanguage() {
            this.settingsList.transactionUnique.forEach((element, index) => {
                let data = this.selectOptionsByLang[this.transactionLang].find(
                    item => item.value == element.value.value
                );

                if (data) {
                    this.settingsList.transactionUnique[index].value = data;
                }
            });
        },
        deselecting(resource) {
            let removeByindex = this.settingsList.transactionUnique
                .map(transaction => transaction.field)
                .indexOf(resource.value);
            this.settingsList.transactionUnique.splice(removeByindex, 1);
        },

        selected() {
            let transactionLang = {};

            if (this.selectedResource.length == 0) {
                this.settingsList.transactionUnique = [];
            } else {
                this.selectedResource.forEach(element => {
                    let transaction = this.renderTransactionValue(
                        element.value,
                        this.settingsList.transaction_ids
                    );
                    transactionLang = transaction;
                });

                this.settingsList.transactionUnique.push(transactionLang[0]);
            }
        },
        addConfigurationParams(params, value) {
            if (this.copySettingsResult[0][params] !== value) {
                //Cheking value variable
                this.configurationParams[params] = value.value || value;
            }
        },

        renderTransactionValue(key) {
            let planArray = [];
            planArray.push({field: key, value: ''});

            return planArray;
        },

        renderTransactionDropdown(language, field) {
            let gettingData = this.selectOptions.filter(element => {
                if (element.language == language && element.field == field)
                    return element;
            });

            return gettingData;
        },

        renderUniqueTransaction(resourceToCheck) {
            return new Promise((resolve, reject) => {
                this.$useJwt.transactionUnique().then(res => {
                    const {eng_fields} = res.data;
                    const {swe_fields} = res.data;
                    const allResource = resourceToCheck.map(
                        element => element.resource
                    );

                    allResource.forEach(element => {
                        for (let key in eng_fields[element]) {
                            this.selectOptions.push({
                                language: 'eng_fields',
                                label: key,
                                field: element,
                                value: eng_fields[element][key],
                            });
                        }

                        for (let key in swe_fields[element]) {
                            this.selectOptions.push({
                                language: 'swe_fields',
                                label: key,
                                field: element,
                                value: swe_fields[element][key],
                            });
                        }
                    });

                    resolve(true);
                });
            });
        },

        generateTransactionID(key, data) {
            let getField = this.selectOptionsByLang[
                this.transactionLang
            ].filter(element => element.field == key);

            let getValue = getField.find(
                transaction => transaction.value == data.field
            );

            return getValue;
        },

        getConfiguration() {
            this.loadingState = true;
            this.showSettingsField = false;
            this.$useJwt.configure().then(res => {
                const {data} = res.data;

                const _ = this;
                this.renderUniqueTransaction(data[0].resources_to_check)
                    .then(res => {
                        let settingsObject = {
                            ...data[0],
                            transactionUnique: [],
                        };
                        let planArray = [];

                        this.selectedResource.forEach(element => {
                            function firstRender(key, array) {
                                let data = array.find(
                                    element => element.resource == key
                                );

                                planArray.push({
                                    field: key,
                                    value: data
                                        ? _.generateTransactionID(key, data)
                                        : '',
                                });
                            }

                            firstRender(element.value, data[0].transaction_ids);
                        });

                        settingsObject.transactionUnique = planArray;

                        this.settingsList = settingsObject;

                        this.copySettings = settingsObject;
                    })
                    .finally(() => {
                        this.loadingState = false;
                        this.showSettingsField = true;
                    });
            });
        },

        updateConfiguration(value) {
            this.$refs.form.validate().then(res => {
                if (res) {
                    this.loading = true;
                    let newObj = {
                        automatically_handle_duplicates:
                            value.automatically_handle_duplicates,
                        resources_to_check: [],
                        notify_about_new_duplicates:
                            value.notify_about_new_duplicates,
                        notification_email: value.notification_email,
                        transaction_ids: [],
                    };
                    value.transactionUnique.forEach(element => {
                        // newObj.transaction_ids.push({
                        //     field: element.value.value,
                        //     resource: element.field,
                        // });

                        if (element.value !== '') {
                            newObj.transaction_ids.push({
                                field: element.value.value,
                                resource: element.field,
                            });
                        }
                    });
                    // this.selectedResource.forEach(element => {
                    //     newObj.resources_to_check.push({
                    //         resource: element.value,
                    //     });
                    // });

                    this.$useJwt
                        .updateConfigure(value.uuid, newObj)
                        .then(res => {
                            this.popupMsg(
                                this.$t('Message.Success'),
                                res.data[this.message],
                                'CheckIcon',
                                'success'
                            );

                            this.getConfiguration();
                        })
                        .catch(error => {
                            if (error.response) {
                                this.popupMsg(
                                    this.$t('Message.Failed'),
                                    error.response.data[this.message],
                                    'AlertTriangleIcon',
                                    'danger'
                                );
                            }
                        })
                        .finally(() => (this.loading = false));
                }
            });
        },

        generateImage() {
            let getSpan = document.querySelectorAll('.spanGuides p');

            getSpan.forEach(element => {
                let getImg = element.getElementsByTagName('img');

                if (getImg.length > 0) {
                    getImg.forEach(img => {
                        let imgSrc = img.src;

                        let baseUrl = window.location.origin;

                        let imgLocationResult = imgSrc.split(baseUrl)[1];

                        img.onload = () => {
                            img.style.width = '100%';
                            img.style.height = '';
                        };

                        img.setAttribute(
                            'src',
                            `${process.env.VUE_APP_SERVICE_URL}${imgLocationResult}`
                        );
                    });
                }
            });
        },

        getGuide() {
            this.$useJwt.guide().then(res => {
                const {results} = res.data;

                this.guideText = results;
            });
        },
    },

    mounted() {
        this.getGuide();
        this.getConfiguration();
    },
};
</script>

<style scoped lang="scss">
.per-page-selector-send-method {
    width: 100%;
}

.resource-selector {
    width: 93%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
